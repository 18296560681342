<template>
  <div class="sctp-container">
    <div class="pad-tb10">
      <div class="mg-b10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>我的服务订单</el-breadcrumb-item>
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="order-detail">
        <el-row type="flex" :gutter="15">
          <el-col :span="5">
            <div class="sctp-bg-white" style="height: 100%;">
              <div class="flex flex-center" style="font-size: 48px;font-weight: 700;height: 100%;">
                广告
              </div>
            </div>
          </el-col>
          <el-col :span="19">
            <div class="flex-item1">
              <div class="card pad15">
                <div class="sctp-card-title">订单详情</div>
              </div>
              <template v-if="orderDetailConfig.detail">
                <div class="card">
                  <el-card shadow="never">
                    <div class="flex flex-center">
                      <div class="mg-b20">
                        订单状态：<span class="fz-16 sctp-bold">{{ status }}</span>
                      </div>
                    </div>
                    <div>
                      <order-step :step-array="stepArray" :active="currentStep"></order-step>
                    </div>
                  </el-card>
                </div>

                <div class="card order-item">
                  <div
                    class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                    <div class="sctp-mar-r15">订单号：{{ orderDetailConfig.detail.orderno }}</div>
                    <div class="sctp-mar-r15">提交时间：{{
                        dateToString(orderDetailConfig.detail.createtime)
                      }}
                    </div>
                  </div>
                  <div class="goods-list"
                       @click="optionsConfig.onClick('orderCycle')">
                    <el-row type="flex" align="middle" class="sctp-pad15 goods-item">
                      <el-col :span="12">
                        <div class="">
                          <div class="sctp-flex">
                            <el-image
                              class="sctp-mar-r15"
                              :src="orderDetailConfig.detail.serviceinfo.servicecovers[0]"
                              style="width: 80px; height: 80px"
                              fit="cover"></el-image>
                            <div class="sctp-flex-item-1">
                              <router-link
                                target="_blank"
                                :to="`/service/view/${orderDetailConfig.detail.serviceid}`"
                              >
                                <h3>
                                  {{ orderDetailConfig.detail.serviceinfo.servicename }}
                                </h3>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">¥{{
                            orderDetailConfig.detail.serviceinfo.serviceprice
                          }}
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">
                          {{ status }}
                          <template v-if="orderDetailConfig.detail.status === 8">
                            原因：{{ orderDetailConfig.detail.refusereason }}
                          </template>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">
                          <template v-if="orderDetailConfig.detail.hostingamount">
                            {{ orderDetailConfig.detail.hostingamount }}元
                          </template>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="options-btn-group"></div>
                      </el-col>
                    </el-row>
                    <div class="pad-tb10 pad-lr15 sctp-info flex feedback">
                      <el-row type="flex" style="width: 100%;" align="middle">
                        <el-col :span="20">
                          <div style="line-height: 1.5;">
                            <div>用户{{
                                orderDetailConfig.detail.userinfo.account
                              }}提交了购买服务需求，请及时确认（超过24小时未确认自动终止）
                            </div>
                            <div>用户提交时间：{{
                                dateToString(orderDetailConfig.detail.createtime)
                              }}
                            </div>
                            <div>期望完成日期：{{
                                dateToString(orderDetailConfig.detail.expectedDeliveryTime,
                                  'yyyy-MM-dd')
                              }}
                              <span class="mg-l10"
                                    v-if="diffDay(orderDetailConfig.detail.expectedDeliveryTime, orderDetailConfig.detail.createtime)">
                          ({{
                                  diffDay(orderDetailConfig.detail.expectedDeliveryTime,
                                    orderDetailConfig.detail.createtime)
                                }}天)
                        </span>
                              <span class="mg-l10">
                           总预算费用{{ orderDetailConfig.detail.budget.toMoney() }}元
                        </span>
                            </div>
                            <div class="sctp-pad-tb5">
                              附加要求：{{ orderDetailConfig.detail.remark || '无' }}
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="2">
                          <div class="options-btn-group">
                            <template v-if="orderDetailConfig.detail.status === 0">
                              <el-button
                                size="mini" type="primary"
                                @click="optionsConfig.onClick('accept')"
                              >
                                同意
                              </el-button>
                              <el-button
                                @click="optionsConfig.onClick('refuse')"
                                size="mini"
                              >
                                拒绝
                              </el-button>
                            </template>
                            <template v-if="orderDetailConfig.detail.status === 2">
                              <el-button
                                size="mini" type="primary"
                                @click="optionsConfig.onClick('start')"
                              >
                                开始服务
                              </el-button>
                              <el-button
                                @click="optionsConfig.onClick('refuse')"
                                size="mini"
                              >
                                拒绝
                              </el-button>
                            </template>
                            <template v-if="orderDetailConfig.detail.status === 3">
                              <el-button
                                @click="optionsConfig.onClick('end')"
                                size="mini" type="primary"
                              >
                                结束服务
                              </el-button>
                            </template>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </div>

              </template>

            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog
      center
      title="商家拒绝服务"
      width="500px"
      :visible.sync="refuseConfig.show"
    >
      <el-form :rules="refuseConfig.formRules" :ref="refuseConfig.formName"
               :model="refuseConfig.form"
               class="shopInfoForm" label-width="100px">
        <el-form-item prop="reason">
          <el-select v-model="refuseConfig.form.reason" placeholder="请选拒绝理由">
            <el-option
              v-for="item in (refuseConfig.condition && refuseConfig.condition.reason) || []"
              :key="item.dropdownvalue"
              :label="item.dropdownvalue"
              :value="item.dropdownvalue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="refuseConfig.onSubmitClick"
            size="small" type="primary">
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {service} from "@/apis";
import {differenceInCalendarDays} from "date-fns";

export default {
  name: "ServiceOrderDetail",
  components: {
    OrderStep: () => import('../../order/OrderStep'),
  },
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      orderDetailConfig: {
        detail: undefined,
        loadDetail: () => {
          service.orderDetails({
            orderId: this.id,
            userId: this.user.userId,
          }).then(res => {
            let {data} = res;
            this.orderDetailConfig.detail = data;
          })
        },
      },
      optionsConfig: {
        options: {
          accept: ({item,}) => {
            this.$confirm(`请确定按照用户的要求${differenceInCalendarDays(item.expectedDeliveryTime,
              item.createtime)}天，服务费￥${item.budget.toMoney()}元能完成？`, '提示').then(() => {
              service.optionsService({orderId: item.orderid, status: 1}).then(() => {
                this.orderDetailConfig.loadDetail();
              })
            })
          },
          refuse: ({item,}) => {
            this.refuseConfig.showDialog();
          },
          start: ({item,}) => {
            this.$confirm('确认接受，开始服务?', '提示').then(() => {
              service.optionsService({orderId: item.orderid, status: 3}).then(() => {
                this.orderDetailConfig.loadDetail();
              })
            })
          },
          details: ({item,}) => {
            this.$router.push({
              path: `/order/service/orderDetail4Shop/${item.orderid}`,
            })
          },
          end: ({item,}) => {
            this.$confirm('确认服务完成?', '提示').then(() => {
              service.optionsService({orderId: item.orderid, status: 4}).then(() => {
                this.orderDetailConfig.loadDetail();
              })
            })
          },
        },
        onClick: (option) => {
          let item = this.orderDetailConfig.detail;
          if (this.optionsConfig.options[option]) {
            this.optionsConfig.options[option].call(null, {item: item});
          }
        }
      },
      refuseConfig: {
        show: false,
        condition: null,
        formName: 'RefuseConfigFormName',
        form: {
          reason: null,
        },
        formRules: {
          reason: [
            {required: true, message: '请选择原因', trigger: ['blur', 'change']},
          ]
        },
        onSubmitClick: () => {
          this.$refs[this.refuseConfig.formName].validate()
          .then(res => {
            this.refuseConfig.doSubmit();
          });
        },
        doSubmit: () => {
          service.refuseService({
            userId: this.user.userId,
            orderId: this.id,
            reason: this.refuseConfig.form.reason
          }).then(res => {
            this.$message.success('操作成功');
            this.refuseConfig.hiddenDialog();
            this.orderDetailConfig.loadDetail();
          })
        },
        showDialog: () => {
          if (this.refuseConfig.condition === null) {
            this.refuseConfig.loadCondition().then(res => {
              this.refuseConfig.show = true;
            })
          } else {
            this.refuseConfig.show = true;
          }
        },
        hiddenDialog: () => {
          this.refuseConfig.show = false;
        },
        loadCondition: () => {
          return service.refuseCondition().then(res => {
            let {data} = res;
            this.refuseConfig.condition = data;
          })
        },
      },

    }
  },
  computed: {
    diffDay() {
      return function (left, right) {
        return differenceInCalendarDays(left, right);
      }
    },
    status() {
      let status = {
        0: {label: '待确认'},
        1: {label: '等待对方确认并托管资金'},
        2: {label: '已托管资金，待确认'},
        3: {label: '服务中...'},
        4: {label: '确认结束，待买家确认.'},
        5: {label: '买家已确认，服务结束.'},
        6: {label: ''},
        7: {label: ''},
        8: {label: '已拒绝'},
        9: {label: ''},
      };
      let {status: state, isTransfer} = this.orderDetailConfig.detail;
      let ret = status[state]
      let temp = JSON.parse(JSON.stringify(ret));
      if (temp) {
        if ([5, 6, 7, 9].includes(state)) {
          if (isTransfer) {
            temp.label = temp.label + '平台已打款.'
          } else {
            temp.label = temp.label + '待平台打款.'
          }
        }
        return temp.label;
      }
      return '';
    },
    currentStep() {
      let active = 1;
      let {status: orderStatus} = this.orderDetailConfig.detail;
      if (orderStatus) {
        switch (orderStatus) {
          case 0:
            active = 1;
            break;
          case 1:
            active = 2;
            break;
          case 2:
            active = 3;
            break;
          case 3:
            active = 4;
            break;
          case 4:
            active = 5;
            break;
          case 5:
            active = 6;
            break;
          case 6:
          case 7:
          case 9:
            active = 7;
            break;
        }
      }
      return active;
    },
    stepArray() {
      let {submitTime, payTime, deliveryTime, receiptTime, evaluateTime, finishTime} = this.orderDetailConfig.detail;
      return [
        {
          title: '待确认',
          description: this.dateToString(submitTime),
          date: submitTime,
        },
        {
          title: '等待对方确认并托管资金',
          description: this.dateToString(payTime),
          date: payTime,
        },
        {
          title: '已托管资金，待确认',
          description: this.dateToString(deliveryTime),
          date: deliveryTime,
        },
        {
          title: '服务中...',
          description: this.dateToString(receiptTime),
          date: receiptTime,
        },
        {
          title: '确认结束，待买家确认.',
          description: this.dateToString(evaluateTime),
          date: evaluateTime,
        },
        {
          title: '买家已确认，服务结束.',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
        {
          title: '已完成',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
      ];
    },
  },
  beforeMount() {
    this.orderDetailConfig.loadDetail();
  }
}
</script>

<style scoped lang="scss">
/deep/ {
  .order-item {
    border-width: 1px;
    border-color: #e5e5e5;
    border-style: solid;
    margin: 15px 0;

    .feedback {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        background: #e5e5e5;
        top: 0;
        left: 10px;
        right: 10px;
      }
    }
  }

  .options-btn-group {
    display: flex;
    flex-direction: column;

    & .el-button + .el-button {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}
</style>
